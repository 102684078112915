import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(initReactI18next) // Passes i18n down to react-i18next.
  .use(LanguageDetector) // Passes a language detector.
  .init({
    resources: {
      en: {
        translation: {
          // Impressum
          "Impressum": "Impressum",
          "companyInfoTitle": "Company Information",
          "companyName": "DeepReturn GmbH",
          "address": "Seftigenstrasse 19, 3007 Bern, Switzerland",
          "email": "Email: info@deepreturn.ch",
          "uidNumber": "UID: CHE-277.679.338",
          "managingDirector": "Managing Director: Luca Kern",
          "commercialRegister": "Commercial Register: Registered in the Commercial Register of the Canton of Bern",
          // General
          "Log In": "Log In",
          "Sign up here": "Sign up here",
          "Don't have an account?": "Don't have an account?",
          "Privacy Policy": "Privacy Policy",
          "All rights reserved": "All rights reserved",
          "Back to Main Page": "Back to Main Page",

          // AdHistory
          "Ad History": "Ad History",
          "Advertisement": "Advertisement",
          "Created At": "Created At",
          "Copy to clipboard": "Copy to clipboard",
          "Copied!": "Copied!",
          "Loading...": "Loading...",
          "Error fetching ads. Please try again later.": "Error fetching ads. Please try again later.",
          "No ads found for your account.": "No ads found for your account.",
          "Back to Home": "Back to Home",
          "Today": "Today",
          "Last 10 Days": "Last 10 Days",
          "Last 30 Days": "Last 30 Days",
          "Custom Range": "Custom Range",
          "Start Date": "Start Date",
          "End Date": "End Date",

          // Landing Page
          "Payment Options": "Payment Options",
          "Automatic Listing Creation": "Automatic Listing Creation",
          "Simply upload your property images, and our AI will create a complete listing for you, saving you time and effort.": "Simply upload your property images, and our AI will create a complete listing for you, saving you time and effort.",
          "10 Ads for 10 CHF": "10 Ads for 10 CHF",
          "100 Ads for 69 CHF": "100 Ads for 69 CHF",
          "0.69 CHF per Ad": "0.69 CHF per Ad",
          "1 CHF per Ad": "1 CHF per Ad",
          "Try it for free! No credit card required.": "No credit card required.",
          "Welcome to PicToAd": "Welcome to PicToAd",
          "Your AI-Powered Real Estate Ad Creator": "Your AI-Powered Real Estate Ad Creator",
          "Create Stunning Real Estate Listings Instantly": "Create Stunning Real Estate Listings Instantly",
          "Upload your photos – our AI generates listings and gathers surrounding information automatically": "Upload your photos – our AI generates listings and gathers surrounding information automatically",
          "Create Your Listing Now": "Create Your Listing Now",
          "Home": "Home",
          "Features": "Features",
          "Pricing": "Pricing",
          "Try it out": "Try it out",
          "AI-Powered Ad Generation": "AI-Powered Ad Generation",
          "Generate compelling real estate ads with the help of advanced AI technology.": "Generate compelling real estate ads with the help of advanced AI technology.",
          "Multilingual Support": "Multilingual Support",
          "Create ads in multiple languages to reach a broader audience.": "Create ads in multiple languages to reach a broader audience.",
          "Image Enhancement": "Image Enhancement",
          "Optimize and enhance your property images to attract more viewers.": "Optimize and enhance your property images to attract more viewers.",
          "Customizable Templates": "Customizable Templates",
          "Choose from various ad styles to suit your needs.": "Choose from various ad styles to suit your needs.",
          "Surroundings Information": "Surroundings Information",
          "Automatically gather and include information about local leisure, schools, and public transportation in ads.": "Automatically gather and include information about local leisure, schools, and public transportation in ads.",

          // Redeem Code
          "Redeem Your Code": "Redeem Your Code",
          "Enter your code": "Enter your code",
          "Redeem": "Redeem",
          "Invalid or already used code": "Invalid or already used code",

          // Account and Payment
          "Payment Successful!": "Payment Successful!",
          "Your credits have been added to your account.": "Your credits have been added to your account.",
          "Credits": "Credits",
          "Buy Credits": "Buy Credits",
          "Purchase Credits": "Purchase Credits",
          "Select Credits": "Select Credits",
          "10 Credits for 10 CHF": "10 Credits for 10 CHF",
          "100 Credits for 69 CHF": "100 Credits for 69 CHF",
          "Try for Free": "Try for Free",
          "Pay 10 CHF": "Pay 10 CHF",
          "Pay 69 CHF": "Pay 69 CHF",
          "Standard": "Standard",
          "Premium": "Premium",
          "Enterprise": "Enterprise",
          "Custom plans for large companies": "Custom plans for large companies",
          "Contact Us": "Contact Us",
          "Buy Now": "Buy Now",

          // Ad Creation
          "Log out": "Log out",
          "View Ad History": "View Ad History",
          "Initializing": "Initializing",
          "Fetching Nearby Amenities": "Fetching Nearby Amenities",
          "Generating Advertisement": "Generating Advertisement",
          "Completed": "Completed",
          "Buy Price (CHF)": "Buy Price (CHF)",
          "Net buy price in CHF": "Net buy price in CHF",
          "Gross buy price in CHF": "Gross buy price in CHF",
          "Enter Date or leave blank if available from now": "Enter Date or leave blank if available from now",
          "Learn more about Ad Styles": "Learn more about Ad Styles",
          "Learn more about Additional Info": "Learn more about Additional Info",
          "Learn more about Viewing Appointments": "Learn more about Viewing Appointments",
          "Generating your ad, hang tight...": "Generating your ad, hang tight...",
          "Include Nearby Amenities": "Include Nearby Amenities",
          "Extra Costs (CHF)": "Extra Costs (CHF)",
          "Fetching nearby amenities...": "Fetching nearby amenities...",
          "Generating ad...": "Generating ad...",
          "Generating your ad, hang tight...": "Generating your ad, hang tight...",
          "Nearby Amenities": "Nearby Amenities",
          "Include Nearby Amenities": "Include Nearby Amenities",
          "Restaurant": "Restaurant",
          "School": "School",
          "Supermarket": "Supermarket",
          "Submit Ad": "Generate Ad",
          "Additional Info": "Additional Info",
          "Ad Style": "Ad Style",
          "Narrative": "Narrative",
          "Factual": "Factual",
          "Minimalist": "Minimalist",
          "Emotional": "Emotional",
          "Automatic": "Automatic",
          "Ad Style Explanations": "Ad Style Explanations",
          "Narrative style focuses on telling a story about the property, highlighting its unique features and history.": "Narrative style focuses on telling a story about the property, highlighting its unique features and history.",
          "Factual style provides straightforward, detailed information about the property, emphasizing data and specifics.": "Factual style provides straightforward, detailed information about the property, emphasizing data and specifics.",
          "Minimalist style uses concise language and a clean layout to present essential information about the property.": "Minimalist style uses concise language and a clean layout to present essential information about the property.",
          "Emotional style conveys the atmosphere and feeling of the property, highlighting emotions and the lifestyle it enables.": "Emotional style conveys the atmosphere and feeling of the property, highlighting emotions and the lifestyle it enables.",
          "Automatic style generates a highly appealing and professional real estate advertisement, giving the AI flexibility in presentation.": "Automatic style generates a highly appealing and professional real estate advertisement, giving the AI flexibility in presentation.",
          "Enter additional information": "Enter additional information",
          "Select one": "Select one",
          "Area (sqm)": "Area (sqm)",
          "Enter area in sqm": "Enter area in sqm",
          "Available From": "Available From",
          "Rent Price Net (CHF)": "Rent Price Net (CHF)",
          "Net rent price in CHF": "Net rent price in CHF",
          "Rent Price Gross (CHF)": "Rent Price Gross (CHF)",
          "Gross rent price in CHF": "Gross rent price in CHF",
          "Buy Price Net (CHF)": "Buy Price Net (CHF)",
          "Net buy price in CHF": "Net buy price in CHF",
          "Buy Price Gross (CHF)": "Buy Price Gross (CHF)",
          "Gross buy price in CHF": "Gross buy price in CHF",
          "Floor": "Floor",
          "Floor number": "Floor number",
          "Type": "Type",
          "Rent": "Rent",
          "Buy": "Buy",
          "Address": "Address",
          "Enter address": "Enter address",
          "Show address in ad": "Show address in ad",
          "Rental Use": "Rental Use",
          "Apartment": "Apartment",
          "Single Family House": "Single Family House",
          "Multi Family House": "Multi Family House",
          "Duplex": "Duplex",
          "Store": "Store",
          "Commercial Space": "Commercial Space",
          "Parking Spot": "Parking Spot",
          "e.g. First occupancy, still being renovated.": "e.g. First occupancy, still being renovated.",
          "Ad Style Explanations": "Ad Style Explanations",
          "Narrative style focuses on telling a story about the property, highlighting its unique features and history.": "Narrative style focuses on telling a story about the property, highlighting its unique features and history.",
          "Factual style provides straightforward, detailed information about the property, emphasizing data and specifics.": "Factual style provides straightforward, detailed information about the property, emphasizing data and specifics.",
          "Minimalist style uses concise language and a clean layout to present essential information about the property.": "Minimalist style uses concise language and a clean layout to present essential information about the property.",
          "Additional Info Explanations": "Additional Info Explanations",
          "Our AI can create housing ads directly from images, but it's helpful to fill in information here that the AI cannot see on images. Required fields are Rent Price (if not price on request) and the address (this helps the AI check around the area and include highlights in the ad).": "Our AI can create housing ads directly from images, but it's helpful to fill in information here that the AI cannot see on images. Required fields are Rent Price (if not price on request) and the address (this helps the AI check around the area and include highlights in the ad).",
          "Unsupported format": "Unsupported format",
          "Supported formats": "Supported formats",

          // Viewing Appointments
          "Viewing Appointments": "Viewing Appointments (optional)",
          "Add viewing appointments for the ad": "You can add viewing appointments to display them in the ad. These are optional but can help potential tenants schedule visits more conveniently.",
          "Viewing Appointment 1: DATE and TIME": "Viewing Appointment 1: DATE and TIME",
          "Available from now": "Available from now",
          "Enter Date or leave blank if available from now": "Enter Date or leave blank if available from now",
          "Date and Time": "Date and Time",
          "Select date and time": "Select date and time",
          "Time": "Time",
          "Add Appointment": "Add Appointment",

          // Miscellaneous
          "Please fill in all required fields.": "Please fill in all required fields.",
          "Ad copied to clipboard!": "Ad copied to clipboard!",
          "Drag and drop your images here or click to select images": "Drag and drop your images here or click to select images",
          "Suggested images:": "Suggested images:",
          "Indoor pictures": "Indoor pictures",
          "Pictures of the surroundings": "Pictures of the surroundings",
          "Floor plan (Grundrissplan) if possible": "Floor plan if possible",
          "This screenshot will help the AI to make the ad": "This screenshot will help the AI to make the ad",
          "Extra Costs (CHF)": "Extra Costs (CHF)",
          "Extra costs in CHF": "Extra costs in CHF",
          "Price on Request": "Price on Request",
          "Ad Language": "Ad Language",
          "English": "English",
          "German": "German",
          "French": "French",
          "Available from now": "Available from now",
          "Per Ad": "Per Ad",
          "Quick Links": "Quick Links",
          "Follow Us": "Follow Us",
          "Redeem Code": "Redeem Code",
          "Calculate Your Savings": "Calculate Your Savings",
          "Ads per month": "Ads per month",
          "Employee Gross Annual Salary": "Employee Gross Annual Salary",
          "Minutes per ad": "Minutes per ad",
          "You can save": "You can save",
          "per year": "per year",
          "Welcome to PicToAd": "Welcome to PicToAd",
          "Your AI-powered real estate ad creator.": "Your AI-powered real estate ad creator.",
          "Log In": "Log In",
          "Don't have an account?": "Don't have an account?",
          "Sign up here": "Sign up here",
          "PicToAd by DeepReturn":  "PicToAd by DeepReturn",
          "Number of rooms": "Number of rooms",
          "Enter number of rooms": "Enter number of rooms",
          "Advertisement": "Advertisement",
          "Created At": "Created At",
          "Copy to clipboard": "Copy to clipboard",
          "Copied!": "Copied!",
          "Loading...": "Loading...",
          "Error fetching ads. Please try again later.": "Error fetching ads. Please try again later.",
          "No ads found for your account.": "No ads found for your account.",
          "Back to Home": "Back to Home",
          "Include Nearby Amenities": "Include Nearby Amenities",
          "Generate Ad": "Generate Ad",


          "preschool": "Preschool",
          "primary_school": "Primary School",
          "secondary_school": "Secondary School",
          "university": "University",
          "library": "Library",
          "park": "Park",
          "playground": "Playground",
          "restaurant": "Restaurant",
          "bar": "Bar",
          "cafe": "Café",
          "bakery": "Bakery",
          "shopping_mall": "Shopping Mall",
          "supermarket": "Supermarket",
          "convenience_store": "Convenience Store",
          "hospital": "Hospital",
          "pharmacy": "Pharmacy",
          "spa": "Spa",
          "train_station": "Train Station",
          "subway_station": "Subway Station",
          "bus_station": "Bus Station",
          "bus_stop": "Bus Stop",
          "taxi_stand": "Taxi Stand",
          "airport": "Airport",
          "gym": "Gym",
          "fitness_center": "Fitness Center",
          "ski_resort": "Ski Resort",
          "stadium": "Stadium",
          "swimming_pool": "Swimming Pool",
          "post_office": "Post Office",

          // privacy plicy
          "Privacy Policy": "Privacy Policy",
          "privacyPolicyIntro": "At DeepReturn GmbH (UID CHE-277.679.338), we are deeply committed to protecting the privacy and security of our clients' data. This Privacy Policy outlines how we collect, store, use, and safeguard your personal information in full compliance with Swiss data protection regulations.",
          "privacyPolicyDataCollection": "We collect personal data only when it is necessary for the delivery of our services. This includes information provided by you during account creation, service usage, and interaction with our platform.",
          "privacyPolicyDataUsage": "The data we collect is used solely for the purpose of providing and improving our services, fulfilling contractual obligations, processing transactions, and ensuring compliance with legal requirements. We employ strict data minimization practices, collecting only the information necessary to fulfill these objectives.",
          "privacyPolicyTransmission": "We may share your data with carefully selected third-party service providers in order to operate and enhance our platform. All third-party partners are bound by stringent confidentiality agreements and are fully compliant with data protection regulations. We never sell or trade personal data to third parties for marketing purposes.",
          "privacyPolicyDataStorage": "All personal data is securely stored on Swiss servers, which are protected by state-of-the-art encryption protocols and maintained to the highest industry standards. Data is only accessible by authorized personnel under strict access control policies. We take extensive measures to ensure that your data remains safe, confidential, and in full compliance with Swiss and international data protection laws.",
          "privacyPolicyRights": "As a user, you retain full control over your data. You have the right to request access to, correction of, or deletion of your personal information at any time. You may also request information about how your data is processed or withdraw consent where applicable.",
          "privacyPolicyChanges": "We reserve the right to modify this Privacy Policy as needed to reflect any changes in our data practices or legal obligations. Any updates will be posted on this page, and material changes will be communicated to our users directly.",
          "privacyPolicyContact": "If you have any questions or concerns regarding your personal data or this Privacy Policy, please contact us at: info@deepreturn.ch. We are committed to resolving any inquiries in a timely and transparent manner."
        
        
        }
      },
      // German (de) translations
      de: {
        translation: {

          "preschool": "Kindergarten",
          "primary_school": "Grundschule",
          "secondary_school": "Sekundarschule",
          "university": "Universität",
          "library": "Bibliothek",
          "park": "Park",
          "playground": "Spielplatz",
          "restaurant": "Restaurant",
          "bar": "Bar",
          "cafe": "Café",
          "bakery": "Bäckerei",
          "shopping_mall": "Einkaufszentrum",
          "supermarket": "Supermarkt",
          "convenience_store": "Convenience Store",
          "hospital": "Krankenhaus",
          "pharmacy": "Apotheke",
          "spa": "Spa",
          "train_station": "Bahnhof",
          "subway_station": "U-Bahn-Station",
          "bus_station": "Bushaltestelle",
          "bus_stop": "Bushaltestelle",
          "taxi_stand": "Taxistand",
          "airport": "Flughafen",
          "gym": "Fitnessstudio",
          "fitness_center": "Fitnesscenter",
          "ski_resort": "Skigebiet",
          "stadium": "Stadion",
          "swimming_pool": "Schwimmbad",
          "post_office": "Postamt",

          //AdHistory
          "Ad History": "Anzeigenhistorie",
          "Advertisement": "Anzeige",
          "Created At": "Erstellt am",
          "Copy to clipboard": "In die Zwischenablage kopieren",
          "Copied!": "Kopiert!",
          "Loading...": "Wird geladen...",
          "Error fetching ads. Please try again later.": "Fehler beim Abrufen der Anzeigen. Bitte versuchen Sie es später noch einmal.",
          "No ads found for your account.": "Keine Anzeigen für Ihr Konto gefunden.",
          "Back to Home": "Zurück zur Startseite",
          "Today": "Heute",
          "Last 10 Days": "Letzte 10 Tage",
          "Last 30 Days": "Letzte 30 Tage",
          "Custom Range": "Benutzerdefinierter Bereich",
          "Start Date": "Startdatum",
          "End Date": "Enddatum",


          // Impressum 
          "Impressum": "Impressum",
          "companyInfoTitle": "Firmeninformationen",
          "companyName": "DeepReturn GmbH",
          "address": "Seftigenstrasse 19, 3007 Bern, Schweiz",
          "email": "E-Mail: info@deepreturn.ch",
          "uidNumber": "UID: CHE-277.679.338",
          "managingDirector": "Geschäftsführer: Luca Kern",
          "commercialRegister": "Tel: +41 76 758 96 80",
          // General
          "Log In": "Anmelden",
          "Sign up here": "Hier registrieren",
          "Don't have an account?": "Haben Sie kein Konto?",
          "Privacy Policy": "Datenschutzrichtlinie",
          "All rights reserved": "Alle Rechte vorbehalten",
          "Back to Main Page": "Zur Hauptseite",
          
          // Landing Page
          "Payment Options": "Zahlungsoptionen",
          "Try it for free! No credit card required.": "Keine Kreditkarte erforderlich.",
          "Automatic Listing Creation": "Automatische Erstellung von Anzeigen",
          "Simply upload your property images, and our AI will create a complete listing for you, saving you time and effort.": "Laden Sie einfach Ihre Immobilienbilder hoch, und unsere KI erstellt eine vollständige Anzeige für Sie, die Ihnen Zeit und Mühe spart.",
          "Welcome to PicToAd": "Willkommen bei PicToAd",
          "Your AI-Powered Real Estate Ad Creator": "Ihr KI-gestützter Immobilienanzeigen-Generator",
          "Create Stunning Real Estate Listings Instantly": "Erstellen Sie Immobilienanzeigen aus Bildern",
          "Upload your photos – our AI generates listings and gathers surrounding information automatically": "Laden Sie Ihre Fotos hoch – unsere KI sammelt Umgebungsinformationen und erstellt automatisch Anzeigen",
          "Create Your Listing Now": "Erstellen Sie jetzt Ihre Anzeige",
          "Home": "Startseite",
          "Features": "Funktionen",
          "Pricing": "Preise",
          "Try it out": "Jetzt ausprobieren",
          "AI-Powered Ad Generation": "KI-gestützte Anzeigenerstellung",
          "Generate compelling real estate ads with the help of advanced AI technology.": "Erstellen Sie überzeugende Immobilienanzeigen mit Hilfe fortschrittlicher KI-Technologie.",
          "Multilingual Support": "Mehrsprachige Unterstützung",
          "Create ads in multiple languages to reach a broader audience.": "Erstellen Sie Anzeigen in mehreren Sprachen, um ein breiteres Publikum zu erreichen.",
          "Image Enhancement": "Bildverbesserung",
          "Optimize and enhance your property images to attract more viewers.": "Optimieren und verbessern Sie Ihre Immobilienbilder, um mehr Betrachter anzuziehen.",
          "Customizable Templates": "Anpassbare Vorlagen",
          "Choose from various ad styles to suit your needs.": "Wählen Sie aus verschiedenen Anzeigestilen, die Ihren Bedürfnissen entsprechen.",
          "Surroundings Information": "Umgebungsinformationen",
          "Automatically gather and include information about local leisure, schools, and public transportation in ads.": "Automatisch Informationen über lokale Freizeitmöglichkeiten, Schulen und öffentliche Verkehrsmittel in Anzeigen aufnehmen.",
          // Redeem Code
          "Redeem Your Code": "Lösen Sie Ihren Code ein",
          "Enter your code": "Geben Sie Ihren Code ein",
          "Redeem": "Einlösen",
          "Invalid or already used code": "Ungültiger oder bereits verwendeter Code",

          // Account and Payment
          "Payment Successful!": "Zahlung erfolgreich!",
          "Your credits have been added to your account.": "Ihre Credits wurden Ihrem Konto gutgeschrieben.",
          "Credits": "Kredite",
          "Buy Credits": "Kredite kaufen",
          "Purchase Credits": "Credits kaufen",
          "Select Credits": "Credits auswählen",
          "10 Credits for 10 CHF": "10 Credits für 10 CHF",
          "100 Credits for 69 CHF": "100 Credits für 69 CHF",
          "Pay 10 CHF": "10 CHF bezahlen",
          "Pay 69 CHF": "69 CHF bezahlen",
          "Try for Free": "Kostenlos ausprobieren",
          "Standard": "Standard",
          "10 Ads for 10 CHF": "10 Anzeigen für 10 CHF",
          "1 CHF per Ad": "1 CHF pro Anzeige",
          "Premium": "Premium",
          "100 Ads for 69 CHF": "100 Anzeigen für 69 CHF",
          "0.69 CHF per Ad": "0,69 CHF pro Anzeige",
          "Enterprise": "Unternehmen",
          "Custom plans for large companies": "Individuelle Pläne für grosse Unternehmen",
          "Standard": "Standard",
          "Premium": "Premium",
          "Enterprise": "Unternehmen",
          "Custom plans for large companies": "Individuelle Pläne für grosse Unternehmen",
          "Contact Us": "Kontaktieren Sie uns",
          "Buy Now": "Jetzt kaufen",


          // Ad Creation
          "Log out": "Ausloggen",
          "Generated Ad": "Generierte Anzeige",
          "View Ad History": "Anzeigeverlauf anzeigen",
          "Copy Ad": "Anzeige Kopieren",
          "Initializing": "Initialisierung",
          "Fetching Nearby Amenities": "Abrufen von nahegelegenen Annehmlichkeiten",
          "Generating Advertisement": "Anzeige wird generiert",
          "Completed": "Abgeschlossen",
          "Buy Price (CHF)": "Kaufpreis (CHF)",
          "Net buy price in CHF": "Kaufpreis netto in CHF",
          "Gross buy price in CHF": "Kaufpreis brutto in CHF",
          "Enter Date or leave blank if available from now": "Datum eingeben oder leer lassen, wenn ab sofort verfügbar",
          "Learn more about Ad Styles": "Erfahren Sie mehr über Anzeigestile",
          "Learn more about Additional Info": "Erfahren Sie mehr über zusätzliche Informationen",
          "Learn more about Viewing Appointments": "Erfahren Sie mehr über Besichtigungstermine",
          "Generating your ad, hang tight...": "Ihre Anzeige wird generiert, bitte warten...",
          "Include Nearby Amenities": "In der Nähe befindliche Annehmlichkeiten einbeziehen",
          "Extra Costs (CHF)": "Nebenkosten (CHF)",
          "Fetching nearby amenities...": "Abrufen von nahegelegenen Annehmlichkeiten...",
          "Generating ad...": "Anzeige wird erstellt...",
          "Generating your ad, hang tight...": "Ihre Anzeige wird erstellt, bitte warten...",
          "Nearby Amenities": "Nahegelegene Annehmlichkeiten",
          "Include Nearby Amenities": "Nahegelegene Annehmlichkeiten einbeziehen",
          "Restaurant": "Restaurant",
          "School": "Schule",
          "Supermarket": "Supermarkt",
          "Include Nearby Amenities": "In der Nähe befindliche Annehmlichkeiten einbeziehen",
          "Generate Ad": "Anzeige generieren",
          "Submit Ad": "Anzeige generieren",
          "Additional Info": "Zusätzliche Infos",
          "Ad Style": "Anzeigenstil",
          "Emotional": "Emotional",
          "Automatic": "Automatisch",
          "Narrative": "Erzählend",
          "Factual": "Faktisch",
          "Minimalist": "Minimalistisch",
          "Enter additional information": "Geben Sie zusätzliche Informationen ein",
          "Select one": "Wählen Sie",
          "Area (sqm)": "Fläche (m²)",
          "Enter area in sqm": "Geben Sie die Fläche in m² ein",
          "Available From": "Verfügbar ab",
          "Rent Price Net (CHF)": "Nettomietpreis (CHF)",
          "Net rent price in CHF": "Nettomietpreis in CHF",
          "Rent Price Gross (CHF)": "Bruttomietpreis (CHF)",
          "Gross rent price in CHF": "Bruttomietpreis in CHF",
          "Buy Price Net (CHF)": "Kaufpreis Netto (CHF)",
          "Net buy price in CHF": "Kaufpreis Netto in CHF",
          "Buy Price Gross (CHF)": "Kaufpreis Brutto (CHF)",
          "Gross buy price in CHF": "Kaufpreis Brutto in CHF",
          "Floor": "Etage",
          "Floor number": "Etagennummer",
          "Type": "Typ",
          "Rent": "Miete",
          "Buy": "Kaufen",
          "Address": "Adresse",
          "Enter address": "Geben Sie die Adresse ein",
          "Show address in ad": "Adresse in der Anzeige anzeigen",
          "Rental Use": "Nutzungsart",
          "Apartment": "Wohnung",
          "Single Family House": "Einfamilienhaus",
          "Multi Family House": "Mehrfamilienhaus",
          "Duplex": "Doppelhaus",
          "Store": "Laden",
          "Commercial Space": "Gewerbefläche",
          "Parking Spot": "Parkplatz",
          "e.g. First occupancy, still being renovated.": "z.B. Erstbezug, wird noch renoviert.",
          "Ad Style Explanations": "Erklärungen zum Anzeigenstil",
          "Narrative style focuses on telling a story about the property, highlighting its unique features and history.": "Der erzählende Stil konzentriert sich darauf, eine Geschichte über die Immobilie zu erzählen und ihre einzigartigen Merkmale und ihre Geschichte hervorzuheben.",
          "Factual style provides straightforward, detailed information about the property, emphasizing data and specifics.": "Der faktische Stil liefert klare, detaillierte Informationen über die Immobilie und betont Daten und Einzelheiten.",
          "Minimalist style uses concise language and a clean layout to present essential information about the property.": "Der minimalistische Stil verwendet eine prägnante Sprache und ein klares Layout, um wesentliche Informationen über die Immobilie zu präsentieren.",
          "Additional Info Explanations": "Erklärungen zu zusätzlichen Informationen",
          "Our AI can create housing ads directly from images, but it's helpful to fill in information here that the AI cannot see on images. Required fields are Rent Price (if not price on request) and the address (this helps the AI check around the area and include highlights in the ad).": "Unsere KI kann Wohnungsanzeigen direkt aus Bildern erstellen, aber es ist hilfreich, hier Informationen auszufüllen, die die KI auf Bildern nicht sehen kann. Erforderliche Felder sind der Mietpreis (falls nicht auf Anfrage) und die Adresse (dies hilft der KI, die Umgebung zu überprüfen und Highlights in die Anzeige aufzunehmen).",
          "Unsupported format": "Nicht unterstütztes Format",
          "Supported formats": "Unterstützte Formate",
          "Emotional style conveys the atmosphere and feeling of the property, highlighting emotions and the lifestyle it enables.": "Der emotionale Stil vermittelt die Atmosphäre und das Gefühl der Immobilie, hebt Emotionen und den ermöglichten Lebensstil hervor.",
          "Automatic style generates a highly appealing and professional real estate advertisement, giving the AI flexibility in presentation.": "Der automatische Stil erzeugt ein sehr ansprechendes und professionelles Immobilieninserat und gibt der KI Flexibilität in der Gestaltung.",

          // Viewing Appointments
          "Viewing Appointments": "Besichtigungstermine (optional)",
          "Add viewing appointments for the ad": "Sie können Besichtigungstermine hinzufügen, um sie in der Anzeige anzuzeigen. Diese sind optional, können aber potenziellen Mietern helfen, Besichtigungen bequemer zu planen.",
          "Viewing Appointment 1: DATE and TIME": "Besichtigungstermin 1: DATUM und ZEIT",
          "Available from now": "Ab sofort verfügbar",
          "Enter Date or leave blank if available from now": "Datum eingeben oder leer lassen, wenn ab sofort verfügbar",
          "Date and Time": "Datum und Uhrzeit",
          "Select date and time": "Datum und Uhrzeit auswählen",
          "Time": "Uhrzeit",
          "Add Appointment": "Termin hinzufügen",

          // Miscellaneous
          "Please fill in all required fields.": "Bitte füllen Sie alle erforderlichen Felder aus.",
          "Ad copied to clipboard!": "Anzeige in die Zwischenablage kopiert!",
          "Drag and drop your images here or click to select images": "Ziehen Sie Ihre Bilder hierher oder klicken Sie, um Dateien auszuwählen",
          "Suggested images:": "Vorgeschlagene Bilder:",
          "Indoor pictures": "Innenaufnahmen",
          "Pictures of the surroundings": "Bilder der Umgebung",
          "Floor plan (Grundrissplan) if possible": "Grundrissplan wenn möglich",
          "This screenshot will help the AI to make the ad": "Dieser Screenshot hilft der KI bei der Erstellung der Anzeige",
          "Extra Costs (CHF)": "Nebenkosten (CHF)",
          "Extra costs in CHF": "Nebenkosten in CHF",
          "Price on Request": "Preis auf Anfrage",
          "Ad Language": "Anzeigensprache",
          "English": "Englisch",
          "German": "Deutsch",
          "French": "Französisch",
          "Available from now": "Ab sofort verfügbar",
          "Per Ad": "Pro Anzeige",
          "Quick Links": "Schnellzugriff",
          "Follow Us": "Folgen Sie uns",
          "Redeem Code": "Code einlösen",
          "Calculate Your Savings": "Berechnen Sie Ihre potentiellen Einsparungen",
          "Ads per month": "Anzeigen pro Monat",
          "Employee Gross Monthly Salary": "Bruttomonatsgehalt des Mitarbeiters",
          "Employee Gross Annual Salary": "Bruttojahresgehalt des Mitarbeiters",
          "Minutes per ad": "Minuten pro Anzeige",
          "You can save": "Sie können",
          "per year": "sparen pro Jahr",
          "Welcome to PicToAd": "Willkommen bei PicToAd",
          "Your AI-powered real estate ad creator.": "Ihr KI-gestützter Immobilienanzeigen-Generator.",
          "Log In": "Anmelden",
          "Don't have an account?": "Haben Sie kein Konto?",
          "Sign up here": "Hier registrieren",
          "PicToAd by DeepReturn":  "PicToAd von DeepReturn",
          "Number of rooms": "Anzahl der Zimmer",
          "Enter number of rooms": "Geben Sie die Anzahl der Zimmer ein",
          "Viewing Appointments Information": "Informationen zu Besichtigungsterminen",
          "You can add viewing appointments to display them in the ad. These are optional but can help potential tenants schedule visits more conveniently.": "Sie können Besichtigungstermine hinzufügen, um sie in der Anzeige anzuzeigen. Diese sind optional, können jedoch potenziellen Mietern helfen, Besichtigungen bequemer zu planen.",
          "Advertisement": "Anzeige",
          "Created At": "Erstellt am",
          "Copy to clipboard": "In die Zwischenablage kopieren",
          "Copied!": "Kopiert!",
          "Loading...": "Wird geladen...",
          "Error fetching ads. Please try again later.": "Fehler beim Abrufen der Anzeigen. Bitte versuchen Sie es später noch einmal.",
          "No ads found for your account.": "Keine Anzeigen für Ihr Konto gefunden.",
          "Back to Home": "Zurück zur Startseite",
          // privacy policy 
          "Privacy Policy": "Datenschutzrichtlinie",
          "privacyPolicyIntro": "Bei der DeepReturn GmbH (UID CHE-277.679.338) legen wir großen Wert auf den Schutz der Privatsphäre und Sicherheit unserer Kunden. Diese Datenschutzrichtlinie beschreibt, wie wir Ihre persönlichen Daten sammeln, speichern, verwenden und in Übereinstimmung mit den schweizerischen Datenschutzbestimmungen schützen.",
          "privacyPolicyDataCollection": "Wir erheben personenbezogene Daten nur, wenn dies zur Erbringung unserer Dienstleistungen erforderlich ist. Dazu gehören Informationen, die Sie bei der Erstellung eines Kontos, bei der Nutzung von Diensten und bei der Interaktion mit unserer Plattform angeben.",
          "privacyPolicyDataUsage": "Die von uns erhobenen Daten werden ausschließlich zur Bereitstellung und Verbesserung unserer Dienstleistungen, zur Erfüllung vertraglicher Verpflichtungen, zur Abwicklung von Transaktionen und zur Einhaltung gesetzlicher Anforderungen verwendet. Wir befolgen strenge Grundsätze der Datenminimierung und erheben nur die Daten, die zur Erreichung dieser Ziele erforderlich sind.",
          "privacyPolicyTransmission": "Ihre Daten können mit sorgfältig ausgewählten Drittanbietern geteilt werden, um den Betrieb und die Verbesserung unserer Plattform zu gewährleisten. Alle Drittpartner unterliegen strengen Vertraulichkeitsvereinbarungen und erfüllen die geltenden Datenschutzbestimmungen. Wir verkaufen oder tauschen personenbezogene Daten niemals zu Marketingzwecken an Dritte.",
          "privacyPolicyDataStorage": "Alle personenbezogenen Daten werden sicher auf Schweizer Servern gespeichert, die durch modernste Verschlüsselungsprotokolle und höchste Branchenstandards geschützt sind. Der Zugriff auf die Daten ist nur autorisierten Mitarbeitern nach strengen Zugangskontrollrichtlinien gestattet. Wir ergreifen umfassende Maßnahmen, um sicherzustellen, dass Ihre Daten sicher, vertraulich und in voller Übereinstimmung mit den schweizerischen und internationalen Datenschutzgesetzen bleiben.",
          "privacyPolicyRights": "Als Benutzer behalten Sie die volle Kontrolle über Ihre Daten. Sie haben jederzeit das Recht, Auskunft über Ihre personenbezogenen Daten zu verlangen, deren Berichtigung oder Löschung zu beantragen. Sie können auch Informationen darüber anfordern, wie Ihre Daten verarbeitet werden, oder Ihre Einwilligung widerrufen, sofern zutreffend.",
          "privacyPolicyChanges": "Wir behalten uns das Recht vor, diese Datenschutzrichtlinie zu ändern, um Änderungen in unseren Datenpraktiken oder rechtlichen Verpflichtungen widerzuspiegeln. Alle Aktualisierungen werden auf dieser Seite veröffentlicht, und wesentliche Änderungen werden unseren Nutzern direkt mitgeteilt.",
          "privacyPolicyContact": "Wenn Sie Fragen oder Bedenken zu Ihren persönlichen Daten oder dieser Datenschutzrichtlinie haben, kontaktieren Sie uns bitte unter: info@deepreturn.ch. Wir verpflichten uns, alle Anfragen zeitnah und transparent zu bearbeiten."
        }
      },
      // French (fr) translations
      fr: {
        translation: {

          // Amenities
          "preschool": "École maternelle",
          "primary_school": "École primaire",
          "secondary_school": "Collège",
          "university": "Université",
          "library": "Bibliothèque",
          "park": "Parc",
          "playground": "Aire de jeux",
          "restaurant": "Restaurant",
          "bar": "Bar",
          "cafe": "Café",
          "bakery": "Boulangerie",
          "shopping_mall": "Centre commercial",
          "supermarket": "Supermarché",
          "convenience_store": "Magasin de proximité",
          "hospital": "Hôpital",
          "pharmacy": "Pharmacie",
          "spa": "Spa",
          "train_station": "Gare",
          "subway_station": "Station de métro",
          "bus_station": "Arrêt de bus",
          "bus_stop": "Arrêt de bus",
          "taxi_stand": "Station de taxi",
          "airport": "Aéroport",
          "gym": "Salle de sport",
          "fitness_center": "Centre de fitness",
          "ski_resort": "Station de ski",
          "stadium": "Stade",
          "swimming_pool": "Piscine",
          "post_office": "Bureau de poste",

          // Impressum
          "Impressum": "Mentions légales",
          "companyInfoTitle": "Informations sur l'entreprise",
          "companyName": "DeepReturn GmbH",
          "address": "Seftigenstrasse 19, 3007 Berne, Suisse",
          "phone": "Téléphone : +41 31 123 45 67",
          "email": "E-mail : info@deepreturn.ch",
          "uidNumber": "UID: CHE-277.679.338",
          "managingDirector": "Directeur général : Luca Kern",
          "commercialRegister": "Registre du commerce : Inscrit au registre du commerce du canton de Berne",
          // General
          "Log out": "Déconnexion",
          "Log In": "Connexion",
          "Sign up here": "Inscrivez-vous ici",
          "Don't have an account?": "Vous n'avez pas de compte?",
          "Privacy Policy": "Politique de confidentialité",
          "All rights reserved": "Tous droits réservés",
          "Back to Main Page": "Retour à la page principale",

          //AdHistory
          "Ad History": "Historique des annonces",
          "Advertisement": "Annonce",
          "Created At": "Créé le",
          "Copy to clipboard": "Copier dans le presse-papiers",
          "Copied!": "Copié!",
          "Loading...": "Chargement...",
          "Error fetching ads. Please try again later.": "Erreur lors de la récupération des annonces. Veuillez réessayer plus tard.",
          "No ads found for your account.": "Aucune annonce trouvée pour votre compte.",
          "Back to Home": "Retour à l'accueil",
          "Today": "Aujourd'hui",
          "Last 10 Days": "10 derniers jours",
          "Last 30 Days": "30 derniers jours",
          "Custom Range": "Période personnalisée",
          "Start Date": "Date de début",
          "End Date": "Date de fin",
          
          // Landing Page
          "Payment Options": "Options de paiement",
          "Automatic Listing Creation": "Création automatique d'annonces",
          "Simply upload your property images, and our AI will create a complete listing for you, saving you time and effort.": "Téléchargez simplement vos images de propriété, et notre IA créera une annonce complète pour vous, vous faisant gagner du temps et des efforts.",
          "Welcome to PicToAd": "Bienvenue chez PicToAd",
          "Your AI-Powered Real Estate Ad Creator": "Votre générateur d'annonces immobilières propulsé par l'IA",
          "Create Stunning Real Estate Listings Instantly": "Créez des annonces immobilières impressionnantes instantanément",
          "Upload your photos – our AI generates listings and gathers surrounding information automatically": "Téléchargez vos photos – notre IA génère des annonces et recueille automatiquement des informations sur les environs",
          "Create Your Listing Now": "Créez votre annonce maintenant",
          "Home": "Accueil",
          "Features": "Caractéristiques",
          "Pricing": "Tarification",
          "Try it out": "Essayez-le",
          "AI-Powered Ad Generation": "Génération d'annonces assistée par l'IA",
          "Generate compelling real estate ads with the help of advanced AI technology.": "Générez des annonces immobilières convaincantes avec l'aide d'une technologie IA avancée.",
          "Multilingual Support": "Support multilingue",
          "Create ads in multiple languages to reach a broader audience.": "Créez des annonces en plusieurs langues pour atteindre un public plus large.",
          "Image Enhancement": "Amélioration d'image",
          "Optimize and enhance your property images to attract more viewers.": "Optimisez et améliorez vos images immobilières pour attirer plus de spectateurs.",
          "Customizable Templates": "Modèles personnalisables",
          "Choose from various ad styles to suit your needs.": "Choisissez parmi divers styles d'annonces pour répondre à vos besoins.",
          "Surroundings Information": "Informations sur les environs",
          "Automatically gather and include information about local leisure, schools, and public transportation in ads.": "Rassembler automatiquement des informations sur les loisirs locaux, les écoles et les transports publics dans les annonces.",

          // Redeem Code
          "Redeem Your Code": "Utilisez votre code",
          "Enter your code": "Entrez votre code",
          "Redeem": "Valider",
          "Invalid or already used code": "Code invalide ou déjà utilisé",

          // Account and Payment
          "Payment Successful!": "Paiement réussi!",
          "Your credits have been added to your account.": "Vos crédits ont été ajoutés à votre compte.",
          "Credits": "Crédits",
          "Buy Credits": "Acheter des crédits",
          "Purchase Credits": "Acheter des crédits",
          "Select Credits": "Sélectionner des crédits",
          "10 Credits for 10 CHF": "10 crédits pour 10 CHF",
          "100 Credits for 69 CHF": "100 crédits pour 69 CHF",
          "Pay 10 CHF": "Payer 10 CHF",
          "Pay 69 CHF": "Payer 69 CHF",
          "Try for Free": "Essai gratuit",
          "Try it for free! No credit card required.": "Essayez gratuitement! Pas de carte de crédit requise.",
          "Standard": "Standard",
          "10 Ads for 10 CHF": "10 annonces pour 10 CHF",
          "1 CHF per Ad": "1 CHF par annonce",
          "Premium": "Premium",
          "100 Ads for 69 CHF": "100 annonces pour 69 CHF",
          "0.69 CHF per Ad": "0,69 CHF par annonce",
          "Enterprise": "Entreprise",
          "Custom plans for large companies": "Plans personnalisés pour les grandes entreprises",
          "Standard": "Standard",
          "Premium": "Premium",
          "Enterprise": "Entreprise",
          "Custom plans for large companies": "Plans personnalisés pour les grandes entreprises",
          "Contact Us": "Contactez-nous",
          "Buy Now": "Acheter maintenant",

          // Ad Creation
          "Generated Ad": "Annonce générée",
          "View Ad History": "Afficher l'historique des annonces",
          "Copy Ad": "Copier l'annonce",
          "Initializing": "Initialisation",
          "Fetching Nearby Amenities": "Récupération des commodités à proximité",
          "Generating Advertisement": "Génération de l'annonce",
          "Completed": "Terminé",
          "Buy Price (CHF)": "Prix d'achat (CHF)",
          "Net buy price in CHF": "Prix d'achat net en CHF",
          "Gross buy price in CHF": "Prix d'achat brut en CHF",
          "Enter Date or leave blank if available from now": "Entrez la date ou laissez vide si disponible dès maintenant",
          "Learn more about Ad Styles": "En savoir plus sur les styles d'annonces",
          "Learn more about Additional Info": "En savoir plus sur les informations supplémentaires",
          "Learn more about Viewing Appointments": "En savoir plus sur les rendez-vous de visite",
          "Generating your ad, hang tight...": "Votre annonce est en cours de génération, veuillez patienter...",
          "Include Nearby Amenities": "Inclure les commodités à proximité",
          "Extra Costs (CHF)": "Coûts supplémentaires (CHF)",
          "Fetching nearby amenities...": "Récupération des commodités à proximité...",
          "Generating ad...": "Génération de l'annonce...",
          "Generating your ad, hang tight...": "Votre annonce est en cours de création, un instant...",
          "Nearby Amenities": "Commodités à proximité",
          "Include Nearby Amenities": "Inclure les commodités à proximité",
          "Restaurant": "Restaurant",
          "School": "École",
          "Supermarket": "Supermarché",
          "Include Nearby Amenities": "Inclure les commodités à proximité",
          "Generate Ad": "Générer une annonce",
          "Submit Ad": "Générer une annonce",
          "Additional Info": "Infos supplémentaires",
          "Ad Style": "Style de l'annonce",
          "Narrative": "Narratif",
          "Factual": "Factuel",
          "Emotional": "Émotionnel",
          "Automatic": "Automatique",
          "Minimalist": "Minimaliste",
          "Enter additional information": "Entrez des informations supplémentaires",
          "Select one": "Sélectionnez-en un",
          "Area (sqm)": "Surface (m²)",
          "Ad Style Explanations": "Explications du style d'annonce",
          "Narrative style focuses on telling a story about the property, highlighting its unique features and history.": "Le style narratif se concentre sur le récit de l'histoire de la propriété, en mettant en avant ses caractéristiques uniques et son histoire.",
          "Factual style provides straightforward, detailed information about the property, emphasizing data and specifics.": "Le style factuel fournit des informations détaillées et directes sur la propriété, en mettant l'accent sur les données et les spécificités.",
          "Minimalist style uses concise language and a clean layout to present essential information about the property.": "Le style minimaliste utilise un langage concis et une mise en page épurée pour présenter les informations essentielles sur la propriété.",
          "Emotional style conveys the atmosphere and feeling of the property, highlighting emotions and the lifestyle it enables.": "Le style émotionnel transmet l'atmosphère et le ressenti de la propriété, en mettant en avant les émotions et le style de vie qu'elle permet.",
          "Automatic style generates a highly appealing and professional real estate advertisement, giving the AI flexibility in presentation.": "Le style automatique génère une annonce immobilière très attrayante et professionnelle, donnant à l'IA une flexibilité dans la présentation.",
          "Enter area in sqm": "Entrez la surface en m²",
          "Available From": "Disponible à partir de",
          "Rent Price Net (CHF)": "Loyer net (CHF)",
          "Net rent price in CHF": "Loyer net en CHF",
          "Rent Price Gross (CHF)": "Loyer brut (CHF)",
          "Gross rent price in CHF": "Loyer brut en CHF",
          "Buy Price Net (CHF)": "Prix d'achat net (CHF)",
          "Net buy price in CHF": "Prix d'achat net en CHF",
          "Buy Price Gross (CHF)": "Prix d'achat brut (CHF)",
          "Gross buy price in CHF": "Prix d'achat brut en CHF",
          "Floor": "Étage",
          "Floor number": "Numéro de l'étage",
          "Type": "Type",
          "Rent": "Louer",
          "Buy": "Acheter",
          "Address": "Adresse",
          "Enter address": "Entrez l'adresse",
          "Show address in ad": "Afficher l'adresse dans l'annonce",
          "Rental Use": "Utilisation locative",
          "Apartment": "Appartement",
          "Single Family House": "Maison individuelle",
          "Multi Family House": "Maison multifamiliale",
          "Duplex": "Duplex",
          "Store": "Magasin",
          "Commercial Space": "Espace commercial",
          "Parking Spot": "Place de parking",
          "e.g. First occupancy, still being renovated.": "par ex. Première occupation, encore en rénovation.",
          "Ad Style Explanations": "Explications sur le style de l'annonce",
          "Narrative style focuses on telling a story about the property, highlighting its unique features and history.": "Le style narratif se concentre sur le récit d'une histoire sur la propriété, en mettant en évidence ses caractéristiques uniques et son histoire.",
          "Factual style provides straightforward, detailed information about the property, emphasizing data and specifics.": "Le style factuel fournit des informations claires et détaillées sur la propriété, en mettant l'accent sur les données et les spécificités.",
          "Minimalist style uses concise language and a clean layout to present essential information about the property.": "Le style minimaliste utilise un langage concis et une mise en page épurée pour présenter les informations essentielles sur la propriété.",
          "Additional Info Explanations": "Explications sur les infos supplémentaires",
          "Our AI can create housing ads directly from images, but it's helpful to fill in information here that the AI cannot see on images. Required fields are Rent Price (if not price on request) and the address (this helps the AI check around the area and include highlights in the ad).": "Notre IA peut créer des annonces immobilières directement à partir d'images, mais il est utile de remplir les informations ici que l'IA ne peut pas voir sur les images. Les champs obligatoires sont le prix du loyer (si ce n'est pas sur demande) et l'adresse (cela aide l'IA à vérifier les environs et à inclure des points forts dans l'annonce).",
          "Unsupported format": "Format non supporté",
          "Supported formats": "Formats pris en charge",

          // Viewing Appointments
          "Viewing Appointments": "Rendez-vous de visite (optionnel)",
          "Add viewing appointments for the ad": "Vous pouvez ajouter des rendez-vous de visite pour les afficher dans l'annonce. Ceux-ci sont optionnels mais peuvent aider les locataires potentiels à planifier des visites plus facilement.",
          "Viewing Appointment 1: DATE and TIME": "Rendez-vous de visite 1: DATE et HEURE",
          "Available from now": "Disponible dès maintenant",
          "Enter Date or leave blank if available from now": "Entrez la date ou laissez vide si disponible dès maintenant",
          "Date and Time": "Date et heure",
          "Select date and time": "Sélectionnez la date et l'heure",
          "Time": "Heure",
          "Add Appointment": "Ajouter un rendez-vous",

          // Miscellaneous
          "Please fill in all required fields.": "Veuillez remplir tous les champs obligatoires.",
          "Ad copied to clipboard!": "Annonce copiée dans le presse-papiers!",
          "Drag and drop your images here or click to select images": "Faites glisser et déposez vos images ici ou cliquez pour sélectionner des fichiers",
          "Suggested images:": "Images suggérées:",
          "Indoor pictures": "Photos d'intérieur",
          "Pictures of the surroundings": "Photos des environs",
          "Floor plan (Grundrissplan) if possible": "Plan d'étage si possible",
          "This screenshot will help the AI to make the ad": "Cette capture d'écran aidera l'IA à créer l'annonce",
          "Extra Costs (CHF)": "Coûts supplémentaires (CHF)",
          "Extra costs in CHF": "Coûts supplémentaires en CHF",
          "Price on Request": "Prix sur demande",
          "Ad Language": "Langue de l'annonce",
          "English": "Anglais",
          "German": "Allemand",
          "French": "Français",
          "Available from now": "Disponible dès maintenant",
          "Per Ad": "Par annonce",
          "Quick Links": "Liens rapides",
          "Follow Us": "Suivez-nous",
          "Redeem Code": "Utilisez le code",
          "Calculate Your Savings": "Calculez vos économies",
          "Ads per month": "Annonces par mois",
          "Employee Gross Monthly Salary": "Salaire mensuel brut de l'employé",
          "Minutes per ad": "Minutes par annonce",
          "You can save": "Vous pouvez économiser",
          "per year": "par an",
          "Welcome to PicToAd": "Bienvenue chez PicToAd",
          "Your AI-powered real estate ad creator.": "Votre générateur d'annonces immobilières propulsé par l'IA.",
          "Log In": "Connexion",
          "Don't have an account?": "Vous n'avez pas de compte?",
          "Sign up here": "Inscrivez-vous ici",
          "PicToAd by DeepReturn": "PicToAd par DeepReturn",
          "Number of rooms": "Nombre de pièces",
          "Enter number of rooms": "Entrez le nombre de pièces",
          "Viewing Appointments Information": "Informations sur les rendez-vous de visite",
          "You can add viewing appointments to display them in the ad. These are optional but can help potential tenants schedule visits more conveniently.": "Vous pouvez ajouter des rendez-vous de visite pour les afficher dans l'annonce. Ceux-ci sont optionnels mais peuvent aider les locataires potentiels à planifier des visites plus facilement.",
          "Advertisement": "Annonce",
          "Created At": "Créé le",
          "Copy to clipboard": "Copier dans le presse-papiers",
          "Copied!": "Copié!",
          "Loading...": "Chargement...",
          "Error fetching ads. Please try again later.": "Erreur lors de la récupération des annonces. Veuillez réessayer plus tard.",
          "No ads found for your account.": "Aucune annonce trouvée pour votre compte.",
          "Back to Home": "Retour à l'accueil",
          // privacy policy 
          "Privacy Policy": "Politique de confidentialité",
          "privacyPolicyIntro": "Chez DeepReturn GmbH (UID CHE-277.679.338), nous sommes profondément engagés à protéger la confidentialité et la sécurité des données de nos clients. Cette politique de confidentialité explique comment nous collectons, stockons, utilisons et protégeons vos informations personnelles conformément aux réglementations suisses en matière de protection des données.",
          "privacyPolicyDataCollection": "Nous collectons des données personnelles uniquement lorsque cela est nécessaire à la fourniture de nos services. Cela inclut les informations fournies lors de la création de comptes, de l'utilisation des services et des interactions avec notre plateforme.",
          "privacyPolicyDataUsage": "Les données que nous collectons sont utilisées uniquement dans le but de fournir et d'améliorer nos services, de remplir nos obligations contractuelles, de traiter les transactions et de garantir le respect des exigences légales. Nous appliquons des pratiques strictes de minimisation des données, ne collectant que les informations nécessaires pour atteindre ces objectifs.",
          "privacyPolicyTransmission": "Vos données peuvent être partagées avec des prestataires de services tiers soigneusement sélectionnés dans le but d'exploiter et d'améliorer notre plateforme. Tous les partenaires tiers sont soumis à des accords de confidentialité stricts et respectent pleinement les réglementations en matière de protection des données. Nous ne vendons ni n'échangeons jamais de données personnelles à des fins de marketing.",
          "privacyPolicyDataStorage": "Toutes les données personnelles sont stockées en toute sécurité sur des serveurs suisses, protégées par des protocoles de cryptage de pointe et maintenues selon les normes les plus strictes de l'industrie. Les données ne sont accessibles qu'au personnel autorisé, conformément à des politiques strictes de contrôle d'accès. Nous prenons des mesures étendues pour garantir que vos données restent sûres, confidentielles et conformes aux lois suisses et internationales sur la protection des données.",
          "privacyPolicyRights": "En tant qu'utilisateur, vous gardez le plein contrôle sur vos données. Vous avez le droit de demander l'accès à vos informations personnelles, de les corriger ou de les supprimer à tout moment. Vous pouvez également demander des informations sur la manière dont vos données sont traitées ou retirer votre consentement si nécessaire.",
          "privacyPolicyChanges": "Nous nous réservons le droit de modifier cette politique de confidentialité afin de refléter tout changement dans nos pratiques de données ou nos obligations légales. Toute mise à jour sera publiée sur cette page, et les modifications importantes seront communiquées directement à nos utilisateurs.",
          "privacyPolicyContact": "Si vous avez des questions ou des préoccupations concernant vos données personnelles ou cette politique de confidentialité, veuillez nous contacter à l'adresse : info@deepreturn.ch. Nous nous engageons à répondre à toutes les demandes de manière rapide et transparente."
        }
      }
    },
    fallbackLng: 'de',
    interpolation: {
      escapeValue: false // React already safes from XSS
    }
  });

export default i18n;
